<template>
    <el-card :style="{ 'width': width, 'border-radius': borderRadius }" :body-style="{ padding: '5px 0px' }">
        <div class="flex flex-v-center">
            <i @click="play" :class="isPlaying ? 'el-icon-video-pause' : 'el-icon-video-play'" class="pointer icon"></i>

            <div class="flex1 margin-x" id="waveform" ref="waveform"></div>
            

            <div class="margin-r bold"  style="font-size: 14px;color: #aaa;">{{ format_time(time) }}/{{ format_time(duration) }}</div>
        </div>
    </el-card>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
export default {
    name: "WavePlayer",

    data() {
        return {
            ready: false,
            isPlaying: false,
            wavesurfer: null,
            time: 0,
            duration: 0,
        };
    },

    props: {
        src: {
            type: String,
            default: "",
        },

        width: {
            type: String,
            default: "300px",
        },


        borderRadius: {
            type: String,
            default: "40px",
        },
    },
    created() {
        this.$bus.$on("waveplayer_play", (src) => {
            if (this.isPlaying && this.src != src) {
                this.wavesurfer.stop();
            }
        })
    },

    watch: {
        src(val) {
            if (val) {
                setTimeout(() => {
                    this.wavesurfer.load(val);
                }, 500);
            }
        }
    },
    mounted() {


        this.$nextTick(() => {
            this.wavesurfer = WaveSurfer.create({
                container: this.$refs.waveform,
                // waveColor: '#409EFF',
                height: 20,
                barWidth: 2,
                barHeight: 5,
                cursorColor: "#E6A23C",
                progressColor: "#E6A23C",
                backend: "MediaElement",
                audioRate: "1",
                //使用时间轴插件
            });

            this.wavesurfer.on("play", () => {
                this.isPlaying = true;
                this.$bus.$emit("waveplayer_play", this.src)
            });


            this.wavesurfer.on('pause', () => {
                this.isPlaying = false;
            });

            this.wavesurfer.on('ready', () => {
                this.ready = true;
                this.duration = parseInt(this.wavesurfer.getDuration());
            });

            this.wavesurfer.on('audioprocess', () => {
                this.time = parseInt(this.wavesurfer.getCurrentTime());
            });
        });

        if (this.src) {
            setTimeout(() => {
                console.log(this.wavesurfer)
                this.wavesurfer.load(this.src);
            }, 500);
        }
        // 特别提醒：此处需要使用require(相对路径)，否则会报错
    },
    methods: {
        play() {
            if (this.isPlaying) {
                this.wavesurfer.pause();
            } else {
                this.wavesurfer.play();
            }
        },

        format_time(time) {
            if (time < 60) {
                return "00:" + (time > 9 ? time : ("0" + time));
            } else {
                let m = parseInt(time / 60);
                let s = time % 60;
                return (m > 9 ? m : '0' + m) + ":" + (s > 9 ? s : '0' + s)
            }
        },
    },
};
</script>
<style scoped>
.icon {
    font-size: 22px;
    margin-left: 10px;
    color: #E6A23C;
}
</style>
